import React from 'react';
import { Link } from 'gatsby';

export default () => (
	<div className="cta">
		<h2 className="cta__hdg m-hdg">Collaboration</h2>
		<h3 className="cta__sub m-sub">採用活動をアップデートしたい方へ</h3>

		<p className="cta__txt">
			採用活動を最適化して、<br className="u-sp"/>企業活動をブーストさせませんか？<br/>
			中小企業に必要な採用手法を豊富に揃えて、<br className="u-sp"/>ご連絡をお待ちしております。
		</p>

		<p className="m-btn m-btn--white">
			<Link to={'/contact/'}>お問い合わせ</Link>
		</p>

	</div >
)
import React from 'react';
import { Link } from 'gatsby';

export default () => (
	<footer className="footer">
		<div className="footer__inner">
			<h2 className="footer__logo">
				<Link to={'/'}>
					<svg viewBox="0 0 178 32" xmlns="http://www.w3.org/2000/svg"><path d="M8.968 31V.2H.564V31zm18.832.66c3.168 0 6.38-1.276 8.448-3.388L32.332 23.3c-1.232 1.012-2.684 1.804-4.532 1.716-2.42-.132-4.752-1.804-4.752-4.576 0-3.036 2.332-4.488 4.752-4.576 1.628-.088 2.904.528 4.268 1.54l4.18-4.84c-2.112-2.068-5.28-3.388-8.448-3.388-6.776 0-11.792 5.06-11.792 11.264S21.2 31.66 27.8 31.66zM50.68 31V18.24c.088-2.332 2.156-3.212 3.52-3.212 1.276 0 3.124.924 3.124 3.344V31h7.392V17.448c0-5.148-2.684-8.272-8.052-8.272-2.288 0-4.708 1.276-5.984 2.728V.2h-7.392V31zM75.452 7.416c2.024 0 3.652-1.628 3.652-3.652S77.476.112 75.452.112 71.8 1.74 71.8 3.764s1.628 3.652 3.652 3.652zM79.148 31V9.88h-7.392V31zm14.476 0V18.24c.044-2.332 1.496-3.212 2.86-3.212 1.276 0 2.464.924 2.464 3.344V31h7.392V18.24c.088-2.332 1.496-3.212 2.86-3.212 1.276 0 2.464.924 2.464 3.344V31h7.392V17.448c0-5.148-2.024-8.272-7.392-8.272-2.156 0-5.28 1.848-6.336 3.608-1.232-2.288-2.904-3.608-6.38-3.608-2.156 0-4.18 1.276-5.324 2.728V9.88h-7.436V31zm43.912.66c6.204 0 11.44-5.016 11.44-11.22 0-6.248-5.236-11.264-11.44-11.264s-11.44 5.016-11.44 11.264c0 6.204 5.236 11.22 11.44 11.22zm0-6.732c-2.508 0-4.532-2.024-4.532-4.488 0-2.508 2.024-4.532 4.532-4.532s4.532 2.024 4.532 4.532c0 2.464-2.024 4.488-4.532 4.488zM163.452 31V18.24c.044-2.376 2.156-3.212 3.476-3.212s3.168.924 3.168 3.344L170.052 31h7.436V17.448c0-5.192-2.684-8.272-8.052-8.272-2.332 0-4.708 1.276-5.984 2.728V9.88h-7.436V31z" /></svg>
				</Link>
			</h2>
			<div className="footer__nav nav">
				<ul className="nav__list">
					<li className="nav__item">
						<Link to={'/about/'}>私たちについて</Link>
					</li>
					<li className="nav__item">
						<Link to={'/reasons/'}>成果が上がる理由</Link>
					</li>
					<li className="nav__item">
						<Link to={'/solutions/'}>解決できる問題</Link>
					</li>
					{/* <li className="nav__item">
						<Link to={'/case/'}>事例</Link>
					</li> */}
					<li className="nav__item">
						<Link to={'/price/'}>費用</Link>
					</li>
				</ul>
			</div>
		</div>

		<div className="footer__copy copy">
			<p className="copy__text"><small>&copy; 2021 Shraddha.</small></p>
		</div>

	</footer>
)
import React from 'react';

// import Header from './header'
import Navbar from './navbar'
import Footer from './footer'
import Cta from './cta'
import Ga from './ga'

import './sass/app.sass'

import "@fortawesome/fontawesome-svg-core/styles.css"
import { config } from "@fortawesome/fontawesome-svg-core"
config.autoAddCss = false

export default ({ children }) => (
	<div>
		<Navbar />

		{ children }

		<Cta />
		<Footer />
		<Ga />
	</div>
)